import string from '@shared/lib/string'
//
// Usage: 
//  import {numeric, amount, percentage} from '@lib/numeric'
//

var numeric = {};

// in: null,2,100         out: 2
// in: -3,0,100,10        out: 10
// in: 200,0,100          out: 100
numeric.range = (_value, min, max, default_min) => {
    min = min || 0;
    max = max || 100;
    if (default_min !== 0) {
        default_min = default_min || min;
    }
    let value = Number(_value);
    if (!value) {
        return min;
    }
    if (value < min) {
        return default_min;
    }
    if (value > max) {
        return max;
    }
    return value;
}

// in: null, dontcare, dontcare     out: null
// otherwise, see numeric.range
numeric.inRangeOrNull = (_value, min, max, default_min) => {
    if (_value === null || _value === "") {
        return null;
    }
    return numeric.range(_value, min, max, default_min); 
}


// 1.005.toFixed --> 1.00
// See quadriziljoen posts about this on stack overflow.
// Therefore, this workaround.
// 
numeric.round = (num, decimals) => {
    if (undefined === decimals) {
        decimals = 2;
    }
    if (!parseInt(decimals)) {
        decimals = 2;
    }
    // This is a piece of true magic. 
    // I don't understand any character of this but it works and sometimes we just need to accept that.
    // 
    // The purpose of this is to get rid of the infamous javascript rounding problem. 
    // ---> See it for yourself by entering in the browser: 1.5 - 1.49 and find out it is not 0.01. 
    //      So, (1.5 - 1.49 == 0.01) == false. 
    //   
    // 2 decimals: return +(Math.round(num + "e+2")  + "e-2");
    return +(Math.round(num + "e+"+decimals)  + "e-"+decimals);
};

// Convert value to a numeric type
// When the value can not be converted, defaultvalue is returned.
// Examples (assume defaultvalue is -1) :
//     2        => 2
//     "hh"     => Nan
//     ""       => 0
//     "2.0"    => 2
//     "2,1"    => 2.1   
numeric.tonumber = (value, defaultValue, decimals) => {
    if (undefined == defaultValue) {
        defaultValue = NaN;
    }
    var v = '' + value;
    v = v.replace(',', '.');
    var p = parseFloat(v);
    if (isNaN(p)) {
        p = defaultValue;
    }
    if (undefined != decimals) {
        p = numeric.round(p, decimals);
    }
    return p;
};

/**
 * Return the first not empty number in the list. 
 * IMPORTANT: not empty means not null and not 0 !!!!!
 * IMPORTANT 2: returns null when none of the aguments is not empty
 * 
 * Usage example: 
 *      this.paymentdays = numeric.coalesce(relation.days, 30);
 * or:
 *      this.paymentdays = numeric.coalesce(relation.days, system.defaults.days) || 30;
 *      better: 
 *      this.paymentdays = numeric.coalesce(relation.days, system.defaults.days, 30)
 * 
 */
numeric.coalesce = (...theArgs) => {    
    for (var n = 0; n < theArgs.length; n++) {
        if (Number(theArgs[n])) {
            return Number(theArgs[n]);
        }
    }
    return null;
};


// In: 0,               out:      0,00
// In: 12               out:     12,00
// In: 12.23, 1         out:      12,1
// In: 1234567,0        out: 1.234.567
// In: 1234567,1,true   out: 1234567,0

numeric.fmt = (value, decimals, bNoThousandSeparators) => {
    if (null === value || undefined === value) {
        return "";
    }
    var result = numeric.tonumber(value);
    if (isNaN(result)) {
        return "";
    }
    if (undefined !== decimals) {
        value = numeric.round(value, decimals);
        result = value.toFixed(decimals);
    }
    result = ('' + result).replace('.', ','); 

    if (!result) {
        result = "0,00";
    } else {
        if (!bNoThousandSeparators) {    
            // an extra operation to add thousand separators.
            var parts = result.toString().split(",");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            result = parts.join(",");
        }            
    }
    if (undefined == decimals) {
        result = result.replace(',00','');
    }
    return result;
};

var amount = {};

// In: 0,               out:         € 0,00
// In: 0, false         out:           0,00
// In: 12               out:        € 12,00
// In: 12.23            out:        € 12,23
// In: 1234567          out: € 1.234.567,00
amount.fmt = (d, currency, bNoThousandSeparators) => {
    var result = numeric.fmt(d,2, bNoThousandSeparators);
    if (result === "") {
        return "";
    }
    if (false === currency) {
        return result;
    }
    if (true === currency || undefined === currency) {
        currency ="€" ;
    }
    if (!currency || currency == "") {
        return result;
    }
    return "" + currency + " " + result;
};

var percentage = {};

// In: 0,               out:          0,00%
// In: 12               out:         12,00%
// In: 12.23            out:         12,23%
// In: 0.23             out:          0,23%
// In: 0.23, true       out:         23,00%
percentage.fmt = (d, decimals, symbol) => {
    if (undefined === decimals) {
        decimals = 2;
    }
    symbol = symbol || "%";
    var result = numeric.fmt(d, decimals);
    if (result === "") {
        return "";
    }
    return "" + result + symbol;
};


// Shortcut to numeric.range
percentage.range = (_p) => {
    return numeric.range(_p,0,100);
}
// Shortcut to numeric.inRangeOrNull
percentage.inRangeOrNull = (_p, defaultMin) => {
    return numeric.inRangeOrNull(_p,0,100, defaultMin);
}

// Is a given unit a percentage unit?
percentage.isUnitPercentage = (unit) => {
    return string.isInRange(unit, '%', 'pct', 'percent', 'procent', 'proc', 'perc');
}

// Calculate the percentage which 'part' is from 'total'
// in: 0, 1         out: null
// in: 0, 0         out: null
// in: 1100, -11    out: null
// in: 100, -11     out: null
// in: 200, 20      out: 10
// in: 100, 0       out: 0
// in: -1100, -11   out: 10
percentage.from = (total, part, decimals) => {
    if (total == 0) {
        return null;
    }
    if (total*part <0) {
        return null; // xor negative
    }
    var result = (part/total)*100;
    if (undefined === decimals) {
        return result;
    } 
    return numeric.round(result, decimals);
}
// Calculate the percentage pct from the total.
// in: 0, 1         out: 0
// in: 1, 0         out: 0
// in: 1100, 10     out: 110
// in: 3.14159267, 100,2     out: 3.14
percentage.calc = (total, pct, decimals) => {
    if (!total) {
        return 0;
    }
    if (!pct) {
        return 0; // xor negative
    }
    var result = (total * (pct/100));
    if (undefined === decimals) {
        return result;
    } 
    return numeric.round(result, decimals);
}

export {amount, numeric, percentage};