<template>
    <Dialog :dialog="dlg" :dt="dt" cmd-menu>

        <template v-slot:filter="{onFocus}">

            <TextField @focus="onFocus" ref="q" dusk="select-search" block max-length="255" placeholder="Zoek..." v-model="dt.filter.q"></TextField>

        </template>

        <template v-slot:cmd-menu>
            <ActionMenuItem dusk="menu-create" no-execute @click="onCreate" action='standardtext.create'></ActionMenuItem>
        </template>

    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import string from '@lib/string'

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { standardtexts as api } from '@app/api'
    import ActionButton from '@controls/buttons/ActionButton'
    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'
    import Constants from '@app/consts'
    import {time} from '@lib/date'
    import action from '@app/action'
    import ActionMenuItem from '@controls/menu/ActionMenuItem.vue'
    import appDlg from '@app/dlg'
    const form = ref(null); 
    const q = ref(null)

    var headers = [
        { type: 'cmd'},
        { text: "Titel",             value: "title", tochmaarniettype: 'open', action:"masterdata.standardtexts.open"},
		{ text: "Gewijzigd",         value: "updated_at",  type: 'datetime', visible: false},
		{ text: "Gewijzigd door",    value: "updated_by",     visible: false},
		{ text: "Aangemaakt",        value: "created_at",  type: 'datetime', visible: true},
		{ text: "Aangemaakt door",   value: "created_by",     visible: false},
    ];



    var dt = useDataTable("dt_selectstandardtext", "standardtext", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'de',
            single: 'standaard tekst',
            plural: 'standaard teksten',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            type: '',
            selectfor: '',
        }
    })

    // The text of a standard text must be loaded as we do not pre-load potential long texts. 
    // Note that we only support single select, so we retrieve only one text. 
    class clsSelectStandardText extends clsDialog {
        async onBeforeResolve(items) {
            if (!items ||!items.length) {
                return items;
            }
            let item = items[0];
            let loaded = await api.load(item.id);
            return [loaded.data];
        }
    }

    let dlg = ref(
        new clsSelectStandardText("select_standardtext", "Selecteer standaard tekst", dt, {
            multiselect: false, 
            maxWidth:"1200px" ,
            defaultFocus: ()=>q.value
        })).value;

    /**
     * Create a new standard text
     * And, when saved, select it.
     */
    async function onCreate() {
        try {
            var result = await appDlg.promised.create("standardtext");
            dlg.onSelected([{id: result.id}]);
        } catch (e) {  } // canceled.
    }

</script>
