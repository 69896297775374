<template>
    <Dialog :dialog="dlg" :dt="dt">

        <template v-slot:filter="{onFocus}">

            <TextField @focus="onFocus" ref="q" dusk="select-search" block max-length="255" placeholder="Zoek..." v-model="dt.filter.q"></TextField>

        </template>

            <template v-slot:single-select-cmd="{item, datatable}">
                <span v-if="item.chapters&&!dlg.noSubItemSelect">
                    <ActionMenu>
                        <span class="cursor-pointer"><Icon type='select-from-list'></Icon></span>
                        <template v-slot:dropdown>
                            <ActionMenuItem @click="()=>dt.onSingleSelect(item)">Hoofd-project</ActionMenuItem>                            
                            <ActionMenuItem v-for='(chapter, index) in chaptersToJson(item)' :key='index' @click="()=>dt.onSingleSelect({id_project_chapter: chapter.id, ch_rep: chapter.rep, ...item})">H{{chapter.rep}}. {{ chapter.name }}</ActionMenuItem>                            
                        </template>
                    </ActionMenu>

                </span>
                <span class="cursor-pointer" v-else>
                    <ActionButton dusk="single-select" link-button  @click="(evt) => dt.onSingleSelect(item, evt)">
                        <span><Icon type='select-from-list'></Icon></span>
                    </ActionButton>                    
                </span>
            </template>

    </Dialog>
</template>



<script setup>

    import useDataTable from '@app/useDataTable'
    import string from '@lib/string'

    import Form from '@controls/forms/Form'
    import FormColumn from '@controls/forms/FormColumn'
    import FormRow from '@controls/forms/FormRow'
    import TextField from '@controls/input/TextField'
    import Dialog from '@shared/ui/dialogs/SelectListDialog'
    import { projects as api } from '@app/api'

    import { ref } from 'vue'
    import clsDialog from '@cls/clsSelectListDialog'
    import Constants from '@app/consts'
    import Icon from '@controls/icons/Icon'
    import ActionMenu from "@controls/menu/ActionMenu.vue";        
    import ActionMenuItem from "@controls/menu/ActionMenuItem.vue";        
    import ActionButton from '@controls/buttons/ActionButton'

    const form = ref(null); 
    const id_optimit_type = Constants.optimit_types.project;
    const q = ref(null)

    function chaptersToJson(item) {
        return JSON.parse(item.chapters);
    }

    var headers = [
        { type: 'cmd'},
        { type: "flag" },
        { type: "note" },
		{ value: "rel_name",                  text: "Relatie",              },
		{ value: "pro_number",                text: "Nummer",               },
		{ value: "pro_reference",             text: "Referentie",           },
		{ value: "pro_name",                  text: "Naam",                 },
		{ value: "pp_short_name",             text: "Fase",                 },
        { value: "adr_city",                  text: "Plaats",               },
        { value: "adr_street",                text: "Adres",                fmt: (a,b,item) => string.concat(" ", a, item.adr_street_number) },
        { value: "adr_zipcode",               text: "Postcode",             visible: false, },
        { value: "country_code",              text: "Landcode",             },
		{ value: "pro_start",                 text: "Start",                visible: false, },
		{ value: "pro_end",                   text: "Einde",                visible: false, },
		{ value: "pro_contract_sum",          text: "Aanneemsom",           visible: false, type: 'amount'},
		{ value: "pro_completed",             text: "Project afgerend",     visible: false, },
		{ value: "prs_fullname",              text: "Contactpersoon",       visible: false, },
		{ value: "total_sales",               text: "Totaal opbrengst",     type: 'amount'},
		{ value: "total_purchase",            text: "Totaal inkoop",        type: 'amount'},
		{ value: "total_hours",               text: "Totaal uren",          type: 'amount'},
		{ value: "total_other_costs",         text: "Totaal overige kosten",type: 'amount'},
		{ value: "total_extra_work",          text: "Totaal extra werk",    type: 'amount'},
		{ value: "created_by",                text: "Aangemaakt door",      visible: false, },
		{ value: "updated_by",                text: "Laatste update door",  visible: false, },
		{ value: "created_at",                text: "Aangemaakt",           visible: false, type: 'datetime'},
		{ value: "updated_at",                text: "Laatste update",       visible: false, type: 'datetime'},

    ]

    var dt = useDataTable("dt_selectproject", "project", api, headers, {
        actionDblClick:null,
        itemName: {
            prefix: 'het',
            single: 'project',
            plural: 'projecten',
        }, 
        useCommandPane: false,
        canArchive: false,
        filter: {
            type: '',
            selectfor: '',
        }
    })

    let dlg = ref(
        new clsDialog("select_project", "Selecteer project", dt, {
            multiselect: false, 
            maxWidth:"1200px",
           // noSubItemSelect: false,
            defaultFocus: ()=>q.value
        })).value;

    window.spdlg = dlg;
</script>
