<template>    
    <span class="flat-percentage">{{rep}}</span>
</template>

<script setup>

//
// Usage: 
// import Percentage from "@controls/text/Percentage.vue";
// <Percentage v-model="pct"></Percentage>
//

import {computed, ref} from 'vue'
import {percentage} from '@lib/numeric'

const props = defineProps({
    value: {
        type: [Number, String]
    },
    decimals: {
        type: [Number, String],
        default: 2
    },
    symbol: {
        type: [String],
        default: "%"
    },
})
const rep = computed({
    get() {
        var dec = Number(props.decimals)
        return percentage.fmt(props.value, dec, props.symbol)
    },
})
</script>
