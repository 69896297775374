import clsApiPost from '@cls/clsApiPost'

class clsSalesOrder extends clsApiPost {

    loadStats() {
        return this.post(`stats`); 
    }

    async createInvoice(ids, add_summary_line, ) {
        return this.post('createinvoice', {ids: ids, add_summary_line: add_summary_line})
    }
    async pdf(id, lineids) {
        return this.post('createpdf', {id: id, lineids: lineids})
    }

    async merge(model) {
        return this.post('merge', model)
    }

}

export default clsSalesOrder;
