import { clsModel, fnCreate } from '@cls/clsModel'
import { tenders as api, recipes as apiRecipe } from '@/app/api'
import Constants from '@app/consts'
import bool from '@lib/bool'
import string from '@lib/string'
import {date, datetime} from '@lib/date'
import {tenderstatus, relation, vat as vatlist, unit as unitlist} from '@app/list';
import vat from '@lib/vat'
import noty from '@shared/lib/noty'
import {numeric} from '@lib/numeric'

var modelName = "tender";
const id_optimit_type = Constants.optimit_types.tender;
// The tender header fields
const fields = [
    "id",
    "id_tender_original",
    "tend_total",
    "tend_sub_total",
    "tend_vat_total",
    "tend_number",
    "tend_name",
    "tend_date",
    "tend_exp_date",
    "tend_start_date",
    "tend_attn",
    "tend_reference",
    "tend_tl_onoff",
    "tend_letter",
    "id_status",
    "id_loaded_status",
    "tend_vat_shifted",
    "tend_use_paragraphs",
    "tend_lines_incl_excl_vat",
    "tend_closing_text",

//     "tend_paragraph_new_page",      // obsolete
//     "tend_closing_text_new_page",   // obsolete ? 
    "tend_letter_new_page",
    "each_paragraph_new_page",

    "id_relation",
    "id_project",
    "pro_number",
    "paragraphs",
    "id_person", 
    "id_group", 
    "id_employee_contact", 
    "archived_at",
    "use_sign_portal",
    "id_invoice",
    "inv_number",
];

class clsTenderLine {
    id = null;
    tphp_type = null;
    tphp_amount = null;
    tphp_pd_name = null;
    tphp_purchase_price = null;
    tphp_sales_price = null;
    tphp_unity = null;
    id_product = null;
    id_vat = null;
    id_unity = null;
    pd_code = null;
    pd_supplier_code = null;
    pd_supplier_name = null;
    ean = null;
    sup_name = null;

    constructor(data) {
        data = data || {};
        this.id = data.id || null;
        this.id_vat = data.id_vat || Constants.defaults.id_vat;
        this.tphp_type = data.tphp_type || "invoice_product";
        this.tphp_amount = data.tphp_amount || 1;
        this.tphp_pd_name = data.tphp_pd_name || null;
        this.tphp_purchase_price = data.tphp_purchase_price || 0;
        this.tphp_sales_price = data.tphp_sales_price || 0;
        this.tphp_unity = data.tphp_unity || null;
        this.id_product = data.id_product || null;
        this.id_unity = data.id_unity || null;
        this.pd_code = data.pd_code || null;
        this.pd_supplier_code = data.pd_supplier_code || null;
        this.pd_supplier_name = data.pd_supplier_name || null;
        this.ean = data.ean || null;
        this.sup_name = data.sup_name || null;
    }   

    get isTextLine() {
        return this.tphp_type == "text";
    }

    // :TODO: line_amount is replacing amount_excl
    // That is because the line amount can be either incl. or excl. vat.
    get line_amount()     { 
        return this.amount_excl;
    }    
    get amount_excl()     { 
        var pctFactor = 1; // By default, no percentage.
        if (this.id_unity) {
            let unity = unitlist.one(this.id_unity);            
            if (!!unity.is_percentage) {
                pctFactor = 0.01;
            }
        }

        return pctFactor * this.tphp_amount * this.tphp_sales_price;
    }
    get amount_purchase() { 
        var pctFactor = 1; // By default, no percentage.
        if (this.id_unity) {
            let unity = unitlist.one(this.id_unity);            
            if (!!unity.is_percentage) {
                pctFactor = 0.01;
            }
        }

        return pctFactor * this.tphp_amount * this.tphp_purchase_price;
    }

    // calculate the marge between the purchase - and sales price.
    get marge() {
        if (!Number(this.tphp_purchase_price)) {
            return null;
        }
        if (!Number(this.tphp_sales_price)) {
            return 0;
        }
        var m = 100 * (this.tphp_sales_price - this.tphp_purchase_price) / this.tphp_purchase_price;
        m = numeric.round(m);
        return m;
    }
    // Calculate the sales price from a marge (in pct) and the purchase price.
    set marge(value) {
        if (!Number(this.tphp_purchase_price)) {
            return;
        }
        this.tphp_sales_price = numeric.round(this.tphp_purchase_price * (1 + (value/100)))
    }

    toJSON() {
        return {
            id:                     this.id,
            tphp_type:              this.tphp_type,
            tphp_amount:            this.tphp_amount,
            tphp_pd_name:           this.tphp_pd_name,
            tphp_purchase_price:    this.tphp_purchase_price,
            tphp_sales_price:       this.tphp_sales_price,
            tphp_unity:             this.tphp_unity,
            id_product:             this.id_product,
            id_vat:                 this.id_vat,
            id_unity:               this.id_unity,
            pd_code:                this.pd_code,
            pd_supplier_code:       this.pd_supplier_code,
            pd_supplier_name:       this.pd_supplier_name,
            ean:                    this.ean,
            sup_name:               this.sup_name,
        }
    }
}

class clsParagraph {
    id = null;
    thp_calculate = null;
    thp_name = null;
    thp_sub_total = null;
    lines = [];
    thp_new_page = null;

    constructor(data, defaultSettings) {
        data = data || {};
        this.id = data.id || null;
        this.thp_calculate = bool.isTrue(data.thp_calculate);
        this.thp_name = data.thp_name || null;
        this.thp_sub_total = data.thp_sub_total || null;
        this.thp_new_page = data.thp_new_page || false;
        // Use the default settings for a new paragraph
        if (!this.id) {
            this.thp_new_page = defaultSettings?.thp_new_page || false;
        }

        this.lines = (data.lines||[]).map ( (line) => new clsTenderLine(line));
    }   

    get total() { 
        return (this.lines||[]).reduce( (accumulator, line) => accumulator + (Number(line.amount_excl)||0),0)
    } 
    // :TODO:
    get totalIncl() { 
        return (this.lines||[]).reduce( (accumulator, line) => accumulator + (Number(line.amount_excl)||0),0)
    } 
    // :TODO:
    get totalExcl() { 
        return (this.lines||[]).reduce( (accumulator, line) => accumulator + (Number(line.amount_excl)||0),0)
    } 
    get total_purchase() {
        return (this.lines||[]).reduce( (accumulator, line) => accumulator + (Number(line.amount_purchase)||0),0)
    }
    get totalProfit() { 
        return this.total - this.total_purchase
    }
    // Marges can not be calculated when we have no purchase prices. In this case, the profit is equal to the total.
    get canUseMarge() {
        return this.totalProfit != this.total;
    }
    /**
     * Propagate the vat type to all lines
     */
    propagateVat(id_vat) {
        (this.lines||[]).forEach( (line) => { if (!line.isTextLine) { line.id_vat = id_vat } }); 
    }

    /**
     * Add a line to the vat list
     * @param {*} line 
     */
    _addLine(data, index) {
        console.log('_addLine:', data)
        data = data || {}
        let line = new clsTenderLine(data);
        if (index !== undefined && index >=0) {
            this.lines.splice(index, 0, line);
        } else {
            this.lines.push(line);
            index = this.lines.length -1;
        }
        return this.lines.length-1;
    }

    addLine(product, index) {
        var line = {tphp_type:"invoice_product", tphp_amount: 1, id_vat: vat.ID_VAT_HIGH};
        for (var key in (product||{})) {
            line[key] = product[key];
        }
        return this._addLine(line, index);
    }
    addTextLine(text) {
        text = text ||{text: null}
        let line = {
            tphp_type:       "text",
            tphp_pd_name:    text.text,
        }

        // console.log('Adding: ', text, line)
        return this.addLine(line);
    }

    /**
     * Remove a vat line
     * @param {} line 
     */
    removeLine(removeline) {
        this.lines = this.lines.filter( (line) => line != removeline);
    }

    
    /**
     * Add a product line to the list.
     * @param {} product 
     * @returns 
     */
    addProductLine(product, afterLine, bAmountsInclVat) {
        if (!product) {
            return;
        }
        var ix = -1;
        if (afterLine) {
            ix = this.lines.indexOf(afterLine);
            if (ix >=0) {ix++}
        }

        console.log('addProductLine', product, bAmountsInclVat)
        var tphp_sales_price = product.pd_sales_price_excl_vat;
        if (bAmountsInclVat) {
            tphp_sales_price = product.pd_sales_price_incl_vat;
        }
        let line = {
            id_product: product.id,
            tphp_pd_name:      product.pd_name,
            pd_code:          product.pd_code,
            pd_supplier_code: product.pd_supplier_code,
            pd_supplier_name: product.pd_supplier_name,
            tphp_purchase_price: product.pd_purchase_price ||0,
            tphp_sales_price:  tphp_sales_price,
            id_vat:           product.id_vat,
            id_unity:         product.id_unity,
            sup_name:         product.sup_name,
        }
        
        // console.log('Adding: ', product, line)
        return this.addLine(line, ix >=0 ? ix : undefined);

    }

    async expandRecipe(recipeLine, bAmountsInclVat) {
        this.isDataLoading = true;
        try {
            var amount = recipeLine.tphp_amount; 
            var id_recipe = recipeLine.id_product;
            var result = await apiRecipe.expand(id_recipe, amount);
            var lines = result.data ||[];
            lines.forEach ( (line) => {
                line.id = line.id_product;
                this.addProductLine(line, recipeLine, bAmountsInclVat)
            })
            this.removeLine(recipeLine);
//            console.error(result);
//            this.fill(result.data);                
        } finally {
            this.isDataLoading = false;
        }  
    }

    toJSON() {

        let lines = this.lines.map( (line) => line.toJSON());

        return {
            id: this.id,
            thp_calculate: this.thp_calculate,
            thp_name: this.thp_name,
            thp_sub_total: this.thp_sub_total,
            thp_new_page: this.thp_new_page,
            lines: lines
        }
    }
}


////////////////////////////////////////////////////////////////////////////////
//
//
class clsTender extends clsModel {

    id                            = null;
    id_tender_original            = null;
    tend_total                    = null;
    tend_sub_total                = null;
    tend_vat_total                = null;
    tend_number                   = null;
    tend_name                     = null;
    _tend_date                     = null;
    tend_exp_date                 = null;
    tend_start_date               = null;
    tend_attn                     = null;
    tend_reference                = null;
    tend_tl_onoff                 = null;
    tend_letter                   = null;
    //
    // When the status is loaded as 'sent', most fields are disabled.
    // A user can change the status to concept to make the tender editable again.
    // This is fine, however make sure that the user must save first by caching the initial value of status. 
    id_status                     = null;
    id_loaded_status             = null;

    _tend_vat_shifted              = null;
    _tend_use_paragraphs           = null;
    id_group = null; 

//     tend_paragraph_new_page       = null; // obsolete
//     tend_closing_text_new_page    = null; // obsolete ? 
    tend_letter_new_page          = null;
    each_paragraph_new_page       = null;

    tend_lines_incl_excl_vat      = null;
    tend_closing_text             = null;
    _id_relation                   = null;
    id_person                      = null;
    id_project                    = null;
    id_employee_contact       = null;
    pro_number                      = null;
    paragraphs                    = [];
    archived_at                   = null;
    use_sign_portal               = false;
    userresponse = {};
    id_invoice                    = null;
    inv_number                    = null;

    // When archived_at is filled, the tender is archived.
    get isArchived() {
        return !!this.archived_at;
    }
    get disabled() {
        return super.disabled || this.isArchived;
    }

    get tend_date() {
        return this._tend_date;
    }
    set tend_date(v) {
        this._tend_date = v;
        if (this.isFilling) {
            return;
        }
        var paymentDays = 30;
        let rel = relation.one(this.id_relation);
        if (rel) {
            paymentDays = Number(rel.rel_tender_terms_days) || 30;
        }         
        this.tend_exp_date = date.addDays(this.tend_date, paymentDays);
    }

    /**
     * Default settings for a paragraph.
     */
    get defaultParagraphSettings() {
        return {thp_new_page: this.each_paragraph_new_page};
    }

    _activeParagraph = null;
    // activeParagraph is dynamically set when a paragraph is clicked. 
    // When it is unclicked, it is undefined. 
    // The getter makes sure that when no selection is made, the returned index == -1, which makes it easier to avoid confusion for null, Nan, 0.
    get activeParagraph() {
        if (isNaN(this._activeParagraph)) {
            return -1;
        }
        if (this._activeParagraph === null) {
            return -1;
        }
        return this._activeParagraph;
    }
    set activeParagraph(v) { this._activeParagraph = v; }

    get tend_use_paragraphs() { return this._tend_use_paragraphs; }
    set tend_use_paragraphs(v) { 
        if (v) {
            if (!this.paragraphs?.length) {
                this.addParagraph();
            }
            (this.paragraphs||[]).forEach( (p, ix) => {
                console.log('Para ', p.thp_name)
                if (!p.thp_name) {
                    p.thp_name = `Hoofdstuk ${ix+1}`;
                }
            })
        }
        this._tend_use_paragraphs = v; 
    }
    
    get tend_vat_shifted() {
        return this._tend_vat_shifted;
    }
    set tend_vat_shifted(v) {
        this._tend_vat_shifted = v;
        if (this.isFilling) {
            return;
        }
        if (!v) {
            (this.paragraphs||[]).forEach( (para) => { para.propagateVat(vat.ID_VAT_HIGH); }) ;
        }
    }

    get useAmountsExclVat() {
        return (this.tend_lines_incl_excl_vat||"excl")=="excl";
    }
    set useAmountsExclVat(value) {
        this.tend_lines_incl_excl_vat = value ? "excl" : "incl";
    }
    get useAmountsInclVat() {
        return this.tend_lines_incl_excl_vat == "incl"
    }    
    set useAmountsInclVat(value) {
        this.tend_lines_incl_excl_vat = value ? "incl" : "excl";
    }

    // Add a product to the given paragraph
    addProductLine(product, paragraph) {
        if (!product) {
            console.error('addProductLine: no Product specified')
            return;
        }
        if (!paragraph) {
            console.error('addProductLine: no Paragraph specified')
            return;
        }
        paragraph.addProductLine(product, null, this.useAmountsInclVat);
    }

    get isSingleParagraph() {
        return (this.paragraphs||[]).length == 1
    }

    get singleParagraph() {
        return this.paragraphs[0] || {}
    }
    
    get modelRep() {
        return this.tend_number || "Concept";
    }

    get isStatusConcept() {
        return this.id_status == Constants.tender.status.STATUS_CONCEPT;
    }
    get isStatusOpen() {
        return this.id_status == Constants.tender.status.STATUS_OPEN;
    }
    get isStatusAccepted() {
        return this.id_status == Constants.tender.status.STATUS_ACCEPTED;
    }
    get isStatusRejected() {
        return this.id_status == Constants.tender.status.STATUS_REJECTED;
    }

    get isOpenOrLater() {
        if (!this.id_loaded_status) {
            return false;
        }
        return this.id_loaded_status >= Constants.tender.status.STATUS_OPEN;
    }
    /**
     *  The status to be used by the status chips.
     */
    get chipStatus() {
        switch (this.id_status) {
            case Constants.tender.status.STATUS_CONCEPT    : return 'concept';   // 
            case Constants.tender.status.STATUS_ACCEPTED   : return 'accepted';  // 
            case Constants.tender.status.STATUS_REJECTED   : return 'rejected';  // vervallen
            case Constants.tender.status.STATUS_FINISHED   : return 'processed'; // finished 
            case Constants.tender.status.STATUS_OPEN       : return date.isInPast(this.tend_exp_date) ? "expired" : "open";
            default: break;
        }
        return "new";
    }

    get statusRep() {
        if (!this.id_status) {
            return 'Nieuw';
        }
        if (this.id_status != Constants.tender.status.STATUS_OPEN) {
            return tenderstatus.oneProp(this.id_status, 'name', '-');
        }
        return date.isInPast(this.tend_exp_date) ? "Verlopen" : "Uitstaand";        
    }


    /**
     *  get/set the relation field and propagate defaults for the relation to appropriate fields.
     */
    get id_relation() { return this._id_relation; }
    set id_relation(value) {        
        this._id_relation = value;
        if (this.isFilling) {
            return;
        }
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return;
        }         
        this.tend_vat_shifted = bool.isTrue(rel.rel_vat_shifted);   
        this.paymentdays = Number(rel.rel_tender_terms_days) || 30;
        this.tend_exp_date = date.addDays(this.tend_date, this.paymentdays);
        
    }

    /**
     * The currently selected relation address
     */    
    get rel_address() {
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return "";
        }      
        let street = string.concat(" ", rel.adr_street, rel.adr_street_number);
        let address = string.concat(", ", rel.adr_city, street);        
        if (string.isEmpty(address)) {
            return "(adres is leeg)";
        }
        return address;
    }

    get rel_name() {
        let rel = relation.one(this.id_relation);
        if (!rel) {
            return '-';            
        }         
        return rel.rel_name;
    }

    /**
     * get / set the total payable amount and calculate the new discount in case credit restriction is set.  
     */
    get calc_sub_total() { 
        return this.tend_sub_total;
    }
    get calc_vat_total() { 
        return this.tend_vat_total;
    }
    get calc_total() { 
        return this.tend_total;
    }
    get totalProfit() { 
        var profit = (this.paragraphs||[]).reduce( (accumulator, para) => accumulator + (para.thp_calculate ? Number(para.totalProfit||0) :0), 0);
        
        return profit; 
    }

    /**
     * Make this tender 'accepted', that is, change the status to 'approved'.
     * No further details, just set the status.
     * @returns 
     */
    async accept() {
        this.isDataLoading = true; 
        try {
            return await api.accept(this.id);
        } finally {
            this.isDataLoading = false; 
        }
    }
    /**
     * Make this tender 'rejected', that is, change the status to 'approved'.
     * No further details, just set the status.
     * @returns 
     */
    async reject() {
        this.isDataLoading = true; 
        try {
            return await api.reject(this.id);
        } finally {
            this.isDataLoading = false; 
        }
    }
    /**
     * Make this tender 'finished', which is basicly, archived.
     * No further details, just set the archive status.
     * @returns 
     */
    async finish() {
        this.isDataLoading = true; 
        try {
            return await api.archive(this.id);
        } finally {
            this.isDataLoading = false; 
        }
    }
    /**
     * Set the status to concept.
     * No further details, just set the archive status.
     * @returns 
     */
    async toConcept() {
        this.isDataLoading = true; 
        try {
            var {data} = await api.backToConcept(this.id);
            this.fill(data);
            this.sendSavedEvent();
            return data;
        } finally {
            this.isDataLoading = false; 
        }
    }

    /**
     * Create an invoice for this tender
     * @returns 
     */
    async createInvoice(config) {
        if (this.isNew) {
            return; 
        } 
        config = config || {};
        config.id = this.id;        
        this.isDataLoading = true;
        try {
            var result = await api.createInvoice(config);
            this.sendSavedEvent({id: this.id});
            return result.data;
        } finally {
            this.isDataLoading = false; 
        }
    }

    /**
     * Load defaults for new frm the server.
     * 
     * @param {} defaults 
     * @returns 
     */
    async doCreate(defaults) {
        defaults = defaults || {};
        this.cnt = 0;
        var result = await api.loadNew(defaults);
        return await super.doCreate(result.data);
    }
    
    /**
     * Download the document.
     * @returns 
     */
    async downloadData() {
        if (!this.id) {
            return null;
        }
        return api.downloadData(`download/${this.id}`, true);  
    }

    /**
     * Download the document.
     * @returns 
     */
    async downloadPdf() {
        if (!this.id) {
            throw new "De offerte moet eerst worden opgeslagen.";
        }
        return api.getPdf(this.id);  
    }
    get downloadTitle() {
        if (this.tend_number) {
            return `Offerte ${this.tend_number}`;
        }
        return 'Offerte voorbeeld';
    }

    /**
     * Download the confirmation document.
     * @returns 
     */
    async downloadConfirmationPdf() {
        if (!this.id) {
            throw new "De offerte moet eerst worden opgeslagen.";
        }
        return api.getConfirmationPdf(this.id);  
    }
    

//    async checkBeforeSave(data) {
//        await api.getWarnings(data);     
//    }

    async checkBeforeSend() {
        await api.getWarnings({id: this.id}, "getsendwarnings");     
    }

    addParagraph() {
        let n = this.paragraphs.length + 1;
    
        let para = new clsParagraph({thp_name: `Hoofdstuk ${n}`, thp_calculate: true}, this.defaultParagraphSettings);
        this.paragraphs.push(para);

        this.activeParagraph = Math.max(0, this.paragraphs.length-1);
    }

    /**
     * Remove the provided paragraph
     * @param {} para 
     */
    removeParagraph(para) {
        if (this.paragraphs?.length == 1) {
            noty.alert("Een offerte moet minimaal één hoofdstuk bevatten.");
            return;
        }
        this.paragraphs = (this.paragraphs||[]).filter( (p) => p != para);
    }

    /**
     * clone the given paragraph
     * @param {} para 
     */
    cloneParagraph(para) {
        if (!para || !para.toJSON) {
            return;
        }
        let data = para.toJSON();
        if ( (data.thp_name||"").indexOf('kopie') <0) {
            data.thp_name = `(kopie) ${para.thp_name}`;
        }
        data.id = null;
        for(var n = 0; n < data.lines.length;n++) {
            data.lines[n].id = null;
        }

        this.paragraphs.push(new clsParagraph(data, this.defaultParagraphSettings));
        this.activeParagraph = Math.max(0, this.paragraphs.length-1);
        this.tend_use_paragraphs = true;
    }

    /**
     * Import a tender
     * 1) source has no chapters
     *    - when 1 chapter selected (either 1 from a tender, or a tender without chapters):  import lines in main paragraph
     *    - when 2 or more chapters selected: start using paragraphs, import paragraphs.
     * 
     * 2) source has chapters
     *    - import al data in separate chapters. 
     *     
     */
    async importChapters(id_tender, chapters, actualize) {
        this.isDataLoading = true; 

        try {
            
            var result = await api.importChapters(this.id, id_tender, chapters, actualize);
            var chapters = result.data ||[];
            if (!chapters) {
                return;
            }
            if (!Array.isArray(chapters)) {
                throw new "Import chapters: invalid data format";
            }
            if (this.tend_use_paragraphs || chapters.length > 1) {
                this.tend_use_paragraphs = true;
                chapters.forEach( (para) => {
                    this.paragraphs.push(new clsParagraph(para, this.defaultParagraphSettings));
                })
                this.activeParagraph = Math.max(0, this.paragraphs.length-1);            
            } else {
                var target = this.paragraphs[0];
//                (chapters[0].lines||[]).forEach( (line) => { target.lines.push(line); })
                (chapters[0].lines||[]).forEach( (line) => { target._addLine(line); })
            }
        }
        finally {
            this.isDataLoading = false;             
        }
    }


    get _linesPerVatType() {
        var para = this.paragraphs.filter( (p) => !!p.thp_calculate) ||[];
        var id_vat = this.tend_vat_shifted ? vat.ID_VAT_SHIFTED : null;
        var lines = {};
        (para||[]).forEach( (p) => {
            (p.lines||[]).filter( (l) => !l.isTextLine).forEach( (l) => {
                var lineIdVat = id_vat || l.id_vat;
                if (!lines[lineIdVat]) {
                    lines[lineIdVat] = [];
                }
                lines[lineIdVat].push(l);
            })
        })
        return lines;
    }
    get vatLines() {
        var linesPerType = this._linesPerVatType;
        var result = [];
        for (var id_vat in linesPerType) {
            // lines is e.g. all 21pct lines. 
            var lines = linesPerType[id_vat];
            // The total of the lines, irrrespective whether the total is with or without vat.
            var lineTotal = (lines||[]).reduce( (accumulator, line) => accumulator + (Number(line.line_amount)||0),0);
            var lineAmountExclVat = 0;                
            var lineAmountInclVat = 0;
            var vatAmount = 0;
            if (this.useAmountsExclVat) {
                lineAmountExclVat = lineTotal,
                vatAmount = vat.excl2vat(id_vat, lineAmountExclVat);
                lineAmountInclVat = vatAmount + lineAmountExclVat;
            } else {
                lineAmountInclVat = lineTotal,
                vatAmount  = vat.incl2vat(id_vat, lineAmountInclVat);
                lineAmountExclVat = vat.incl2excl(id_vat, lineAmountInclVat);
            }

            result.push({name: vat.name(id_vat), lineAmountExclVat: lineAmountExclVat, vat: vatAmount, lineAmountInclVat: lineAmountInclVat})
        }
        return result;
    }

    // Is at least one line present in the tender?
    get hasLines() {
        var para = this.paragraphs.find( (p) => p.lines.length);
        return !!para;
    }

    get totalExcl() {
        let lines = this.vatLines||[];
        let total = 0;
        lines.forEach( (line) => {
            total += Number(line.lineAmountExclVat);
        })

        return total || 0;
    }

    get totalIncl() {
        let lines = this.vatLines;
        let total = 0;
        lines.forEach( (line) => {
            total += Number(line.lineAmountInclVat);
        })
        return total || 0;
    }

    get hasUserResponse() {
        return this.userresponse && this.userresponse.signed_by;
    }
    get isUserResponseAccepted() {
        return this.userresponse && this.userresponse.signed_at && !!this.userresponse.accepted;
    }
    get isUserResponseRejected() {
        return this.userresponse && this.userresponse.signed_at && !this.userresponse.accepted;
    }
    get fmtSigned_at() {
        return datetime.fmt.local(this?.userresponse?.signed_at, null, true);
    }
    get portalSignLink() {
        if (!this.isOpenOrLater) {
            return null;
        }
        if (!this.userresponse?.token) {
            return null;
        }
        var baseUrl = process.env.VUE_APP_BASEURL;
        if (!baseUrl) {
            return null;            
        }
        return `${baseUrl}/offerte/tekenen/${this.userresponse.token}`;
    }

    /**
     * Make sure that the vendors array is always present, frontend probably depends on array type. 
     * @param {} data 
     * @returns 
     */
    fill(data) {
        
        data = data ||{};
                
        data.tend_tl_onoff                 = bool.isTrue(data.tend_tl_onoff);
        data.tend_lines_incl_excl_vat      = data.tend_lines_incl_excl_vat ||"excl";
        data.tend_letter_new_page          = bool.isTrue(data.tend_letter_new_page);
        data.each_paragraph_new_page       = bool.isTrue(data.each_paragraph_new_page);
//         data.tend_closing_text_new_page    = bool.isTrue(data.tend_closing_text_new_page);
    
        data.paragraphs                    = data.paragraphs ||[];
        data.tend_vat_shifted              = bool.isTrue(data.tend_vat_shifted);
        data.tend_use_paragraphs           = bool.isTrue(data.tend_use_paragraphs);
        data.use_sign_portal               = bool.isTrue(data.use_sign_portal);
        // Cache the status in the loaded status.
        data.id_loaded_status = data.id_status;
        let result = super.fill(data);

        // let para = new clsParagraph({});
        //this.paragraphs.push(para);
        let paragraphs = (data.paragraphs||[]).map( (para) => new clsParagraph(para, this.defaultParagraphSettings));
        this.paragraphs = paragraphs;
        // We need at least one paragraph.
        if (!this.paragraphs.length) {
            this.addParagraph();
        }
        // Enable paragraphs when explicitely requested or when multiple paragraphs are available.
        this.tend_use_paragraphs = this.tend_use_paragraphs || (this.paragraphs.length > 1);
//
//        this.paragraphs = (data.paragraphs||[]).map( (para) => new clsParagraph(para));
        this.activeParagraph = 0;

        this.userresponse = data.userresponse ||{};
        return result;
    }

    // expand the line in the given paragraph.
    // Caller could have called paragraph itself, however, loading indicator would not be set. 
    async expandRecipe(para, recipeLine) {
        if (!para || !recipeLine) {
            return;
        }
        this.isDataLoading = true;
        try {
            await para.expandRecipe(recipeLine, this.useAmountsInclVat);
        } finally {
            this.isDataLoading = false;
        }  
    }

    constructor() {
        super({
          api: api,   
          modelName: modelName, 
          mandatoryFields: ["id_relation", "tend_name"],
          id_optimit_type: id_optimit_type, 
          fillable: fields
        })
    } 

 }
 export default fnCreate(clsTender , 'clsTender');
