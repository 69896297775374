//
// The content of this file is generated with  a SQL Script
//
//   Generate this list from the database: 
// 
/*
 select 'let rights = {' 
 union select concat (rpad(replace(name, ' ', ''), 36, ' '), ': ', rpad(concat(id, ','), 6, ' '), ' // --- ') 
 	 from rights 
 union select '};'
 union select 'export default rights;'
*/
// 
let rights = {
    organisation_access                 : 100,   // --- 
    organisation_modify                 : 105,   // --- 
    organisation_modify_bank_account    : 106,   // --- 
    organisation_letter_access          : 108,   // --- 
    organisation_letter_modify          : 110,   // --- 
    organisation_alerts                 : 115,   // --- 
    organisation_subscription_access    : 120,   // --- 
    organisation_subscription_modify    : 125,   // --- 
    organisation_mollie_access          : 126,   // --- 
    organisation_mollie_modify          : 127,   // --- 
    user_access                         : 130,   // --- 
    user_modify                         : 131,   // --- 
    user_create                         : 132,   // --- 
    user_remove                         : 133,   // --- 
    email_settings_access               : 140,   // --- 
    email_settings_modify               : 141,   // --- 
    email_settings_create               : 142,   // --- 
    email_settings_remove               : 143,   // --- 
    accountancy_link_access             : 205,   // --- 
    accountancy_link_modify             : 210,   // --- 
    accountancy_link_create             : 215,   // --- 
    accountancy_link_remove             : 220,   // --- 
    link_email_access                   : 225,   // --- 
    link_email_modify                   : 230,   // --- 
    rightgroup_access                   : 300,   // --- 
    rightgroup_modify                   : 305,   // --- 
    rightgroup_create                   : 310,   // --- 
    rightgroup_remove                   : 315,   // --- 
    tariff_access                       : 330,   // --- 
    tariff_modify                       : 335,   // --- 
    tariff_create                       : 340,   // --- 
    tariff_remove                       : 345,   // --- 
    department_access                   : 400,   // --- 
    department_modify                   : 405,   // --- 
    department_create                   : 410,   // --- 
    department_remove                   : 415,   // --- 
    employee_access                     : 500,   // --- 
    employee_modify                     : 505,   // --- 
    employee_create                     : 510,   // --- 
    employee_remove                     : 515,   // --- 
    employee_attachments_access         : 520,   // --- 
    employee_attachments_create         : 525,   // --- 
    employee_attachments_remove         : 530,   // --- 
    employee_note_access                : 531,   // --- 
    employee_note_modify                : 532,   // --- 
    credit_restriction_access           : 540,   // --- 
    credit_restriction_modify           : 545,   // --- 
    credit_restriction_create           : 550,   // --- 
    credit_restriction_remove           : 555,   // --- 
    unit_access                         : 560,   // --- 
    unit_modify                         : 565,   // --- 
    unit_create                         : 570,   // --- 
    unit_remove                         : 575,   // --- 
    jobtitle_access                     : 580,   // --- 
    jobtitle_modify                     : 581,   // --- 
    jobtitle_create                     : 582,   // --- 
    jobtitle_remove                     : 583,   // --- 
    daypart_access                      : 590,   // --- 
    daypart_modify                      : 591,   // --- 
    daypart_create                      : 592,   // --- 
    daypart_remove                      : 593,   // --- 
    email_access                        : 600,   // --- 
    email_modify                        : 605,   // --- 
    email_resend                        : 610,   // --- 
    workordertype_access                : 620,   // --- 
    workordertype_modify                : 621,   // --- 
    workordertype_create                : 622,   // --- 
    workordertype_remove                : 623,   // --- 
    workordertype_customfield_access    : 624,   // --- 
    workordertype_customfield_modify    : 625,   // --- 
    workordertype_customfield_create    : 626,   // --- 
    workordertype_customfield_remove    : 627,   // --- 
    costtype_access                     : 630,   // --- 
    costtype_modify                     : 631,   // --- 
    costtype_create                     : 632,   // --- 
    costtype_remove                     : 633,   // --- 
    dispute_access                      : 640,   // --- 
    dispute_modify                      : 641,   // --- 
    dispute_create                      : 642,   // --- 
    dispute_remove                      : 643,   // --- 
    productgroup_access                 : 650,   // --- 
    productgroup_modify                 : 651,   // --- 
    productgroup_create                 : 652,   // --- 
    productgroup_remove                 : 653,   // --- 
    supplier_access                     : 660,   // --- 
    supplier_modify                     : 661,   // --- 
    supplier_create                     : 662,   // --- 
    supplier_remove                     : 663,   // --- 
    absence_type_access                 : 670,   // --- 
    absence_type_modify                 : 671,   // --- 
    absence_type_create                 : 672,   // --- 
    absence_type_remove                 : 673,   // --- 
    project_group_access                : 680,   // --- 
    project_group_modify                : 681,   // --- 
    project_group_create                : 682,   // --- 
    project_group_remove                : 683,   // --- 
    project_phase_access                : 685,   // --- 
    project_phase_modify                : 686,   // --- 
    project_phase_create                : 687,   // --- 
    project_phase_remove                : 688,   // --- 
    dashboard_access                    : 3000,  // --- 
    dashboard_modify                    : 3005,  // --- 
    widget_revenue                      : 3010,  // --- 
    widget_cost                         : 3015,  // --- 
    widget_cost_revenue                 : 3020,  // --- 
    widget_invoices_expired             : 3025,  // --- 
    widget_invoices_open                : 3030,  // --- 
    widget_purchaseinvoices_payable     : 3035,  // --- 
    widget_sent_items                   : 3040,  // --- 
    widget_problems                     : 3045,  // --- 
    widget_todo                         : 3050,  // --- 
    widget_salesorder_expired           : 3055,  // --- 
    relation_access                     : 4000,  // --- 
    relation_modify                     : 4005,  // --- 
    relation_bankaccount_access         : 4006,  // --- 
    relation_bankaccount_modify         : 4007,  // --- 
    relation_bankaccount_create         : 4008,  // --- 
    relation_bankaccount_remove         : 4009,  // --- 
    relation_create                     : 4010,  // --- 
    relation_remove                     : 4015,  // --- 
    relation_archive                    : 4020,  // --- 
    relation_unarchive                  : 4025,  // --- 
    relation_search                     : 4030,  // --- 
    relation_settings_access            : 4035,  // --- 
    relation_settings_modify            : 4040,  // --- 
    relation_import                     : 4045,  // --- 
    relation_export                     : 4050,  // --- 
    relation_merge                      : 4055,  // --- 
    relation_note_access                : 4060,  // --- 
    relation_note_modify                : 4065,  // --- 
    relation_addresses_access           : 4080,  // --- 
    relation_addresses_modify           : 4085,  // --- 
    relation_addresses_create           : 4090,  // --- 
    relation_addresses_remove           : 4095,  // --- 
    relation_contacts_access            : 4100,  // --- 
    relation_contacts_modify            : 4105,  // --- 
    relation_contacts_create            : 4110,  // --- 
    relation_contacts_remove            : 4115,  // --- 
    relation_attachments_access         : 4120,  // --- 
    relation_attachments_create         : 4125,  // --- 
    relation_attachments_remove         : 4130,  // --- 
    relation_incasso_access             : 4140,  // --- 
    relation_incasso_modify             : 4142,  // --- 
    relation_incasso_create             : 4144,  // --- 
    relation_incasso_remove             : 4146,  // --- 
    purchaseinvoice_access              : 5000,  // --- 
    purchaseinvoice_modify              : 5005,  // --- 
    purchaseinvoice_create              : 5006,  // --- 
    purchaseinvoice_export              : 5010,  // --- 
    purchaseinvoice_remove              : 5015,  // --- 
    purchaseinvoice_search              : 5020,  // --- 
    purchaseinvoice_settings_access     : 5025,  // --- 
    purchaseinvoice_settings_modify     : 5030,  // --- 
    purchaseinvoice_flags               : 5040,  // --- 
    purchaseinvoice_accept              : 5045,  // --- 
    purchaseinvoice_reject              : 5050,  // --- 
    purchaseinvoice_paraph_access       : 5055,  // --- 
    purchaseinvoice_paraph_modify       : 5060,  // --- 
    purchaseinvoice_paraph_modify_others: 5065,  // --- 
    purchaseinvoice_note_access         : 5070,  // --- 
    purchaseinvoice_note_modify         : 5075,  // --- 
    purchaseinvoice_attachments_access  : 5080,  // --- 
    purchaseinvoice_attachments_create  : 5085,  // --- 
    purchaseinvoice_attachments_remove  : 5090,  // --- 
    purchaseinvoice_problems            : 5095,  // --- 
    purchaseinvoice_paymentstatus       : 5096,  // --- 
    salesinvoice_access                 : 6000,  // --- 
    salesinvoice_modify                 : 6005,  // --- 
    salesinvoice_create                 : 6010,  // --- 
    salesinvoice_remove                 : 6015,  // --- 
    salesinvoice_search                 : 6020,  // --- 
    salesinvoice_settings_access        : 6025,  // --- 
    salesinvoice_settings_modify        : 6030,  // --- 
    salesinvoice_export                 : 6035,  // --- 
    salesinvoice_send                   : 6040,  // --- 
    salesinvoice_paraph_access          : 6045,  // --- 
    salesinvoice_paraph_modify          : 6050,  // --- 
    salesinvoice_paraph_modify_others   : 6055,  // --- 
    salesinvoice_flags                  : 6060,  // --- 
    salesinvoice_note_access            : 6065,  // --- 
    salesinvoice_note_modify            : 6070,  // --- 
    salesinvoice_history                : 6085,  // --- 
    salesinvoice_payment_history        : 6090,  // --- 
    salesinvoice_payment_access         : 6095,  // --- 
    salesinvoice_payment_modify         : 6100,  // --- 
    salesinvoice_payment_create         : 6105,  // --- 
    salesinvoice_payment_remove         : 6110,  // --- 
    salesinvoice_attachments_access     : 6115,  // --- 
    salesinvoice_attachments_create     : 6120,  // --- 
    salesinvoice_attachments_remove     : 6125,  // --- 
    salesinvoice_problems               : 6130,  // --- 
    salesinvoice_reminder_access        : 6135,  // --- 
    salesinvoice_reminder_send          : 6140,  // --- 
    products_access                     : 7000,  // --- 
    products_modify                     : 7005,  // --- 
    products_create                     : 7010,  // --- 
    products_remove                     : 7015,  // --- 
    products_export                     : 7020,  // --- 
    texts_access                        : 7200,  // --- 
    texts_modify                        : 7205,  // --- 
    texts_create                        : 7210,  // --- 
    texts_remove                        : 7215,  // --- 
    tender_access                       : 8000,  // --- 
    tender_modify                       : 8005,  // --- 
    tender_create                       : 8010,  // --- 
    tender_remove                       : 8015,  // --- 
    tender_search                       : 8020,  // --- 
    tender_settings_access              : 8025,  // --- 
    tender_settings_modify              : 8030,  // --- 
    tender_export                       : 8035,  // --- 
    tender_send                         : 8040,  // --- 
    tender_paraph_access                : 8045,  // --- 
    tender_paraph_modify                : 8050,  // --- 
    tender_paraph_modify_others         : 8055,  // --- 
    tender_flags                        : 8060,  // --- 
    tender_note_access                  : 8065,  // --- 
    tender_note_modify                  : 8070,  // --- 
    tender_history                      : 8085,  // --- 
    tender_create_invoice               : 8090,  // --- 
    tender_attachments_access           : 8095,  // --- 
    tender_attachments_create           : 8100,  // --- 
    tender_attachments_remove           : 8105,  // --- 
    paymentorder_access                 : 9000,  // --- 
    paymentorder_modify                 : 9005,  // --- 
    paymentorder_create                 : 9010,  // --- 
    paymentorder_remove                 : 9015,  // --- 
    paymentorder_search                 : 9020,  // --- 
    paymentorder_export                 : 9021,  // --- 
    paymentorder_settings_access        : 9025,  // --- 
    paymentorder_settings_modify        : 9030,  // --- 
    paymentorder_download               : 9035,  // --- 
    paymentorder_setpaid                : 9040,  // --- 
    paymentorder_backtoconcept          : 9041,  // --- 
    paymentorder_paraph_access          : 9044,  // --- 
    paymentorder_paraph_modify          : 9045,  // --- 
    paymentorder_paraph_modify_others   : 9050,  // --- 
    paymentorder_flags                  : 9055,  // --- 
    paymentorder_note_access            : 9060,  // --- 
    paymentorder_note_modify            : 9065,  // --- 
    periodicinvoice_access              : 10000, // --- 
    periodicinvoice_modify              : 10005, // --- 
    periodicinvoice_create              : 10010, // --- 
    periodicinvoice_remove              : 10015, // --- 
    periodicinvoice_search              : 10020, // --- 
    periodicinvoice_settings_access     : 10025, // --- 
    periodicinvoice_settings_modify     : 10030, // --- 
    periodicinvoice_flags               : 10035, // --- 
    periodicinvoice_note_access         : 10040, // --- 
    periodicinvoice_note_modify         : 10045, // --- 
    periodicinvoice_export              : 10046, // --- 
    manday_access                       : 11000, // --- 
    manday_modify                       : 11005, // --- 
    manday_create                       : 11010, // --- 
    manday_remove                       : 11015, // --- 
    manday_search                       : 11020, // --- 
    manday_settings_access              : 11025, // --- 
    manday_settings_modify              : 11030, // --- 
    manday_export                       : 11035, // --- 
    manday_send                         : 11040, // --- 
    manday_create_invoice               : 11045, // --- 
    manday_flags                        : 11050, // --- 
    manday_note_access                  : 11055, // --- 
    manday_note_modify                  : 11060, // --- 
    manday_attachments_access           : 11075, // --- 
    manday_attachments_create           : 11080, // --- 
    manday_attachments_remove           : 11085, // --- 
    project_access                      : 12000, // --- 
    project_modify                      : 12005, // --- 
    project_create                      : 12010, // --- 
    project_remove                      : 12015, // --- 
    project_search                      : 12020, // --- 
    project_status_modify               : 12025, // --- 
    project_settings_access             : 12030, // --- 
    project_settings_modify             : 12035, // --- 
    project_export                      : 12040, // --- 
    project_paraph_access               : 12045, // --- 
    project_paraph_modify               : 12050, // --- 
    project_paraph_modify_others        : 12055, // --- 
    project_flags                       : 12060, // --- 
    project_note_access                 : 12065, // --- 
    project_note_modify                 : 12070, // --- 
    project_limits_access               : 12085, // --- 
    project_limits_modify               : 12090, // --- 
    project_limits_create               : 12095, // --- 
    project_limits_remove               : 12100, // --- 
    project_additionalwork_access       : 12105, // --- 
    project_additionalwork_modify       : 12110, // --- 
    project_additionalwork_create       : 12115, // --- 
    project_additionalwork_remove       : 12120, // --- 
    project_additionalwork_invoice      : 12125, // --- 
    project_othercosts_access           : 12130, // --- 
    project_othercosts_modify           : 12135, // --- 
    project_othercosts_create           : 12140, // --- 
    project_othercosts_remove           : 12145, // --- 
    project_hours_access                : 12170, // --- 
    project_hours_modify                : 12175, // --- 
    project_hours_export                : 12180, // --- 
    project_financials                  : 12185, // --- 
    project_employees_access            : 12190, // --- 
    project_employees_create            : 12195, // --- 
    project_employees_remove            : 12200, // --- 
    project_planning_access             : 12205, // --- 
    project_planning_modify             : 12210, // --- 
    project_planning_create             : 12215, // --- 
    project_planning_remove             : 12220, // --- 
    project_planning_archive            : 12225, // --- 
    project_attachments_access          : 12230, // --- 
    project_attachments_create          : 12235, // --- 
    project_attachments_remove          : 12240, // --- 
    project_merge                       : 12245, // --- 
    hours_access                        : 13000, // --- 
    hours_modify                        : 13005, // --- 
    hours_create                        : 13010, // --- 
    hours_remove                        : 13015, // --- 
    hours_search                        : 13020, // --- 
    hours_archive                       : 13025, // --- 
    hours_unarchive                     : 13030, // --- 
    hours_settings_access               : 13035, // --- 
    hours_settings_modify               : 13040, // --- 
    hours_export                        : 13045, // --- 
    hours_paraph_access                 : 13050, // --- 
    hours_paraph_modify                 : 13055, // --- 
    hours_paraph_modify_others          : 13060, // --- 
    hours_flags                         : 13065, // --- 
    hours_note_access                   : 13070, // --- 
    hours_note_modify                   : 13075, // --- 
    hourtype_access                     : 13080, // --- 
    hourtype_modify                     : 13082, // --- 
    hourtype_create                     : 13084, // --- 
    hourtype_remove                     : 13086, // --- 
    workorder_access                    : 14000, // --- 
    workorder_modify                    : 14005, // --- 
    workorder_create                    : 14010, // --- 
    workorder_remove                    : 14015, // --- 
    workorder_search                    : 14020, // --- 
    workorder_archive                   : 14025, // --- 
    workorder_unarchive                 : 14030, // --- 
    workorder_settings_access           : 14035, // --- 
    workorder_settings_modify           : 14040, // --- 
    workorder_export                    : 14045, // --- 
    workorder_paraph_access             : 14050, // --- 
    workorder_paraph_modify             : 14055, // --- 
    workorder_paraph_modify_others      : 14060, // --- 
    workorder_flags                     : 14065, // --- 
    workorder_note_access               : 14070, // --- 
    workorder_note_modify               : 14075, // --- 
    workorder_create_invoice            : 14090, // --- 
    workorder_attachments_access        : 14095, // --- 
    workorder_attachments_create        : 14100, // --- 
    workorder_attachments_remove        : 14105, // --- 
    workorder_send                      : 14106, // --- 
    workorder_planning_access           : 14107, // --- 
    workorder_planning_modify           : 14108, // --- 
    planning_access                     : 15000, // --- 
    planning_modify                     : 15005, // --- 
    planning_create                     : 15010, // --- 
    planning_remove                     : 15015, // --- 
    salesorder_access                   : 16000, // --- 
    salesorder_export                   : 16001, // --- 
    salesorder_search                   : 16002, // --- 
    salesorder_modify                   : 16005, // --- 
    salesorder_create                   : 16010, // --- 
    salesorder_remove                   : 16015, // --- 
    salesorder_archive                  : 16016, // --- 
    salesorder_unarchive                : 16017, // --- 
    salesorder_create_invoice           : 16018, // --- 
    salesorder_settings_access          : 16020, // --- 
    salesorder_settings_modify          : 16025, // --- 
    salesorder_settings_create          : 16030, // --- 
    salesorder_settings_remove          : 16035, // --- 
    salesorder_flags                    : 16040, // --- 
    salesorder_note_access              : 16041, // --- 
    salesorder_note_modify              : 16042, // --- 
    salesorder_attachments_access       : 16043, // --- 
    salesorder_attachments_create       : 16044, // --- 
    salesorder_attachments_remove       : 16045, // --- 
    subscription_access                 : 17000, // --- 
    subscription_modify                 : 17005, // --- 
    subscription_create                 : 17010, // --- 
    subscription_remove                 : 17015, // --- 
    app_workorder_access                : 50000, // --- 
    app_workorder_modify                : 50005, // --- 
    app_workorder_create                : 50010, // --- 
    app_workorder_remove                : 50015, // --- 
    app_hours_access                    : 51000, // --- 
    app_hours_modify                    : 51005, // --- 
    app_hours_create                    : 51010, // --- 
    app_hours_remove                    : 51015, // --- 
    app_planning_access                 : 52000, // --- 
    app_planning_modify                 : 52005, // --- 
    app_planning_create                 : 52010, // --- 
    app_planning_remove                 : 52015, // --- 
    app_purchaseinvoice_upload          : 52020, // --- 
    app_widget_purchase_accept          : 52025, // --- 
    app_widget_purchase_pay             : 52030, // --- 
    app_widget_salesinvoice_expired     : 52035, // --- 
    app_widget_tender_signed            : 52040, // --- 
    app_purchaseinvoice_browse          : 52045, // --- 
    app_widget_salesorder_expired       : 52050, // --- 
    };
export default rights;